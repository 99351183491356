/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { getLocalizedString, getLanguage } from "@icarius-localization/strings";
import { getCountriesSocietiesFromServer, getSocietyDocsFromServer, previewDocumentFromServer } from "../actions";
import { getSocietiesSelector, getDocsSelector, getSelectedCountryDataSelector } from "../selectors";
import Loader from "@icarius-common/loader";
import { RESET } from "../actionTypes";
import {
  setExportToExcelFunction,
  decimalStatusBar,
  setGridName,
  setSocietyName,
  setLocale,
  setCtx,
  agGridLanguage,
  defaultColDef,
  sideBar,
  statusBar,
  onGridReady,
  getChartTheme,
  setTaskControl,
} from "@icarius-table/utils";
import { excelStyles } from "@icarius-table/excelStyles";
import "@icarius-assets/css/grid-dark.scss";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import { shadeHexColor } from "@icarius-utils/colors";
import { exportTableAction } from "src/app/actions";
import { AgGridReact } from "@ag-grid-community/react";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { DownloadIcon } from "@icarius-icons";
import { BASE_URL } from "@icarius-connection/endpoints";
import { previewFromURL } from "@icarius-utils/download";
import paths from "@icarius-localization/paths";
import IcariusLogo from "@icarius-assets/images/icariusLogo";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const FiscalizationGridContainer = (props) => {

  const {
    loading,
    errorTokenCallback,
  } = props;

  const dispatch = useDispatch();
  const societies = useSelector(getSocietiesSelector);
  const selectedCountry = useSelector(getSelectedCountryDataSelector);
  const docs = useSelector(getDocsSelector);

  const agGrid = useRef(null);

  const [showDocs, setShowDocs] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [societySelected, setSocietySelected] = useState(null);

  useEffect(() => {
    !dataFetched && dispatch(getCountriesSocietiesFromServer())
      .then((resp) => {
        if (resp === 403) {
          errorTokenCallback();
        } else {
          setDataFetched(true);
        }
      })
  }, [dispatch, errorTokenCallback, dataFetched])

  useEffect(() => {
    return () => dispatch({ type: RESET });
  }, [dispatch])

  const handleRowClick = row => {
    if (row && row.data !== undefined) {
      setSocietySelected(row.data)
      dispatch(getSocietyDocsFromServer(row.data.nombre, row.data.idFiscal)).then((resp) => {
        if (resp === 403) {
          errorTokenCallback();
        } else {
          setShowDocs(true);
        }
      })
    }
  };

  const onFirstDataRenderedMyPeople = params => {
    let allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });

    params.api.sizeColumnsToFit(allColumnIds);
    params.api.addEventListener("rangeSelectionChanged", decimalStatusBar);
    params.api.addEventListener("filterChanged", decimalStatusBar);
    params.api.addEventListener("filterModified", decimalStatusBar);
    params.api.addEventListener("sortChanged", decimalStatusBar);
    params.api.addEventListener("displayedColumnsChanged", decimalStatusBar);
  }

  const rowDataChanged = (params) => {
    let allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });

    if (!showDocs) {
      params.api.sizeColumnsToFit(allColumnIds);
    } else {
      params.columnApi.autoSizeColumns(allColumnIds);
    }
    params.api.addEventListener("rangeSelectionChanged", decimalStatusBar);
    params.api.addEventListener("filterChanged", decimalStatusBar);
    params.api.addEventListener("filterModified", decimalStatusBar);
    params.api.addEventListener("sortChanged", decimalStatusBar);
    params.api.addEventListener("displayedColumnsChanged", decimalStatusBar);
  }

  const color = "#FB0101";
  const darkerColor = shadeHexColor(color, -0.3);
  const lighterColor = shadeHexColor(color, -0.5);

  //Seteo los colores corporativos
  document.documentElement.style.setProperty("--main-bg-color", color);
  document.documentElement.style.setProperty("--grid-bg-color", darkerColor);
  document.documentElement.style.setProperty("--grid-light-color", lighterColor);

  setExportToExcelFunction((...args) => dispatch(exportTableAction(...args)));
  setGridName(getLocalizedString("documentInspection"));
  setSocietyName("ICARIUS");
  setLocale("en");
  setCtx(agGrid);
  setTaskControl(false);

  const page = showDocs ? paths.showDocs : paths.fiscalization;

  const downloadIconRenderer = props => {
    const downloadDocumentMethod = () => {
      dispatch(previewDocumentFromServer(props.data.code, props.data.society, props.data.client)).then(resp => {
        if (resp?.data?.status === "OK") {
          previewFromURL(BASE_URL + "/fiscalizationFiles/" + resp.data.pdf.path);
        }
      })
    };
    return <span><DownloadIcon htmlColor={"#FB0101"} onClick={downloadDocumentMethod} /></span>;
  };

  const goBackFromDocs = () => {
    setShowDocs(false);
    setSocietySelected(null);
  }

  return (
    <section style={{ width: "100%" }}>
      <Loader open={loading} />
      <div style={{ padding: "20px 100px 0px 100px" }}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={3}>
            <Button
              variant="text"
              className="whiteText"
              color="secondary"
              disableFocusRipple
              disableRipple
              onClick={showDocs ? goBackFromDocs : errorTokenCallback}
              startIcon={<ArrowBackIosIcon />}
            >
              {showDocs ? getLocalizedString("viewCompanies") : getLocalizedString("fiscalizationEnd")}
            </Button>
          </Grid>

          <Grid item xs={3} container justify="center" alignItems="center">
            <IcariusLogo theme={"dark"} height={50} />
          </Grid>

          <Grid item xs={3} container justify="flex-end" alignItems="center">
            {
              selectedCountry &&
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <img src={`https://www.countryflags.io/${selectedCountry.code}/flat/64.png`} alt="" />
                <Typography style={{ marginLeft: 10, fontSize: "1.8rem", fontWeight: 200 }} className="whiteText">{selectedCountry.name}</Typography>
              </div>
            }
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: "20px 100px", minHeight: "24px" }}>
        {
          societySelected &&
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 600 }} className="whiteText">{societySelected.idFiscal}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography style={{ fontWeight: 600 }} align="center" className="whiteText">{societySelected.nombre}</Typography>
            </Grid>

            <Grid container item xs={3} justify="flex-end">
              <Typography style={{ fontWeight: 600 }} align="right" className="whiteText">{societySelected.cantidad}</Typography>
              <Typography style={{ paddingLeft: 5, fontWeight: 400, textTransform: "lowercase" }} align="right" className="whiteText">{getLocalizedString("signedDocuments")}</Typography>
            </Grid>
          </Grid>
        }
      </div>
      <div className={"ag-theme-balham-dark"} style={{ margin: "0px 100px", height: "calc(100vh - 200px)" }}>
        <AgGridReact
          ref={agGrid}
          columnDefs={getColumnDefByPage(page)}
          rowData={showDocs ? docs : societies}
          defaultColDef={defaultColDef}
          accentedSort={true}
          statusBar={statusBar}
          sideBar={sideBar}
          chartThemes={getChartTheme("dark")}
          localeText={getLanguage() !== "es" ? undefined : agGridLanguage.es}
          onRowClicked={showDocs ? null : handleRowClick}
          getContextMenuItems={[]}
          onFirstDataRendered={onFirstDataRenderedMyPeople.bind(this)}
          onRowDataChanged={rowDataChanged.bind(this)}
          excelStyles={excelStyles("#FB0101", "dd/mm/yyyy")}
          onGridReady={onGridReady}
          groupSelectsChildren={true}
          suppressFilterSearch={true}
          animateRows={true}
          suppressRowClickSelection={true}
          modules={AllModules}
          frameworkComponents={{ DownloadIconRenderer: downloadIconRenderer }}
        />
      </div>
      <a id="downloadAnchorElem" style={{ display: "none" }} />
    </section>
  );
}

export default FiscalizationGridContainer;