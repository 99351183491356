import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import {
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Grid,
    TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const gridStyle = { height: 75, padding: "10px" };

const DuplicateDialog = (props) => {

    const { open, handleConfirm, handleClose, tableTypes, usedData } = props;
    const [oldType, setOldType] = useState('');
    const [oldYear, setOldYear] = useState('');
    const [type, setType] = useState('');
    const [year, setYear] = useState('');
    const dispatch = useDispatch();
    const [years, setYears] = useState({});
    const typesToUse = useMemo(() => {
        let typesToUse = [...tableTypes];
        const uniqueInUse = [...new Set(usedData?.map(item => item.data.type))];
        typesToUse = typesToUse.filter(el => uniqueInUse.includes(el.key));

        uniqueInUse.forEach((type) => {
            const index = typesToUse.findIndex(item => item.key === type);
            if (index > -1) {
                const yearsInUse = [...new Set(usedData?.filter(item => item.data.type === type).map(item => item.data.year))];
                years[type] = yearsInUse;
                setYears(years);
            }
        })

        return typesToUse;
    }, [tableTypes, usedData, years])

    const handleSubmit = () => {
        if (!oldType || !oldYear || !type || !year) {
            dispatch(openSnackbarAction({ msg: 'Debe completar todos los valores', severity: "warning" }));
            return;
        }
        if (Number(oldType) === Number(type) && Number(oldYear) === Number(year)) {
            dispatch(openSnackbarAction({ msg: 'Los nuevos registros no pueden tener el mismo tipo de tabla y año que los registros originales', severity: "warning" }));
            return;
        }

        if (year && !/(^[1-2]\d{3}$)/.test(year)) {
            dispatch(openSnackbarAction({ msg: 'El año no es válido', severity: "warning" }));
            return;
        }

        handleConfirm({ oldType, oldYear, type, year });
        handleClose();
    }

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            scroll={"paper"}
            maxWidth={"sm"}
            fullWidth>
            <DialogTitle>
                {'Duplicar feriados y días festivos de forma masiva'}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent style={{ paddingTop: 0 }}>
                <Grid container item xs={12}>
                    <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel required id={`label`}>{'Tipo de tabla'}</InputLabel>
                            <Select
                                value={oldType}
                                labelId={`label`}
                                id={`select`}
                                onChange={(e) => setOldType(e.target.value)}
                                margin={"none"}
                            >
                                {
                                    typesToUse?.map((item) => (
                                        <MenuItem
                                            className={"whiteText"}
                                            key={item.key}
                                            value={item.key}
                                        >
                                            {item.value}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel required id={`label`}>{'Año'}</InputLabel>
                            <Select
                                value={oldYear}
                                labelId={`label`}
                                id={`select`}
                                onChange={(e) => setOldYear(e.target.value)}
                                margin={"none"}
                            >
                                {
                                    years[oldType]?.map((item) => (
                                        <MenuItem
                                            className={"whiteText"}
                                            key={item}
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel required id={`label`}>{'Nuevo tipo de tabla'}</InputLabel>
                            <Select
                                value={type}
                                labelId={`label`}
                                id={`select`}
                                onChange={(e) => setType(e.target.value)}
                                margin={"none"}
                            >
                                {
                                    tableTypes?.map((item) => (
                                        <MenuItem
                                            className={"whiteText"}
                                            key={item.key}
                                            value={item.key}
                                        >
                                            {item.value}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* imputation */}
                        <TextField
                            fullWidth
                            margin={"none"}
                            label={'Año'}
                            required
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleClose} text={'Cancelar'} />
                <ButtonDialogAction onClick={handleSubmit} isAccept text={'Duplicar'} />
            </DialogActions>
        </Dialog>
    );
}

export default DuplicateDialog;
