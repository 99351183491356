import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.festivitiesTable],
        config: {
            headerName: "Tipo de tabla",
            field: "type",
            isObligatory: true,
            isSelect: true,
        }
    },
    {
        pages: [paths.festivitiesTable],
        config: {
            headerName: "Año",
            field: "year",
            isObligatory: true,
            maxLength: 4,
            minLength: 1,
        }
    },
    {
        pages: [paths.festivitiesTable],
        config: {
            headerName: "Fecha de inicio",
            field: "startDate",
            cellEditor: "dateEditor",
            filter: "agTextColumnFilter",
            isDate: true,
            isObligatory: true,
            dataType: "date",
        }
    },
    {
        pages: [paths.festivitiesTable],
        config: {
            headerName: "Fecha final",
            field: "endDate",
            cellEditor: "dateEditor",
            filter: "agTextColumnFilter",
            isDate: true,
            isObligatory: true,
            dataType: "date"
        }
    },
    {
        pages: [paths.festivitiesTable],
        config: {
            headerName: "Descripción",
            field: "description",
            isObligatory: true,
            maxLength: 50,
            minLength: 1
        }
    },
]