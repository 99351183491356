import React, { useState, useEffect } from "react";
import FiscalizationLogin from "./fiscalizationLogin";
import FiscalizationGridContainer from "./fiscalizationGridContainer";
import withAnimatedMount from "@icarius-common/animatedMount";
import Loader from "@icarius-common/loader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Snackbar } from "@icarius-common/snackbar";
import { setAuthInfo } from "@icarius-pages/login/sessionStorage";
import { loading } from "../selectors";
import paths from "@icarius-localization/paths";

const Fiscalization = (props) => {
  const { loading } = props;

  const [selectSocietyIsOpen, setSelectSocietyIsOpen] = useState(false);
  const [changePinIsOpen, setChangePinIsOpen] = useState(false);
  const [changePinCode, setChangePinCode] = useState(null);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get("code");
    const access = params.get("access");
    if (access) setAuthInfo({ token: access }); //para el axios

    setSelectSocietyIsOpen(Boolean(access));
    setChangePinIsOpen(Boolean(code));
    setChangePinCode(code);
  }, [])

  const changePinCallback = () => {
    setChangePinCode(null);
    setChangePinIsOpen(false);

    //volver a fiscalizacion
    window.history.pushState({}, document.title, "/" + paths.fiscalization);
  }

  const closeSocietyPage = () => {
    setSelectSocietyIsOpen(false);
    window.history.pushState({}, document.title, "/" + paths.fiscalization);
  }

  return (
    <section id={"login-page"} style={{ height: "100vh" }}>
      <Loader open={loading} />
      <Snackbar />
      {
        selectSocietyIsOpen ?
          <FiscalizationGridContainer
            loading={loading}
            errorTokenCallback={closeSocietyPage}
          />
          :
          <FiscalizationLogin
            loading={loading}
            changePinIsOpen={changePinIsOpen}
            changePinCode={changePinCode}
            changePinCallback={changePinCallback}
          />
      }
    </section>
  );
}

export default withAnimatedMount(
  connect(
    createStructuredSelector({
      loading: loading,
    })
  )(Fiscalization)
);
