import {
  REQUEST_LOGIN,
  REQUEST_LOGIN_FULFILLED,
  REQUEST_LOGIN_REJECTED,
  CHANGE_PIN_REJECTED,
  CHANGE_PIN,
  CHANGE_PIN_FULFILLED,
  GET_COUNTRIES,
  GET_COUNTRIES_FULFILLED,
  GET_COUNTRIES_REJECTED,
  GET_COUNTRIES_SOCIETIES,
  GET_COUNTRIES_SOCIETIES_FULFILLED,
  GET_COUNTRIES_SOCIETIES_REJECTED,
  GET_DOCS,
  GET_DOCS_FULFILLED,
  GET_DOCS_REJECTED,
  PREVIEW_DOC,
  PREVIEW_DOC_FULFILLED,
  PREVIEW_DOC_REJECTED,
} from "./actionTypes";
import { openErrorDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import {
  getCountries,
  loginInspector,
  getCountrySocieties,
  getSocietyDocs,
  previewDocumentAPI,
  changePassword,
  lostPassword
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

export const getCountriesFromServer = () => async (dispatch) => {
  dispatch({ type: GET_COUNTRIES });

  try {
    let response = await getCountries();

    if (response && response.status === 200) {
      dispatch({ type: GET_COUNTRIES_FULFILLED, payload: response.data.countrys });
    }
    return response;
  } catch (e) {
    dispatch({ type: GET_COUNTRIES_REJECTED, payload: e });
  }
};

export const loginAction = (country, pin) => async (dispatch) => {
  dispatch({ type: REQUEST_LOGIN });

  try {
    let response = await loginInspector({ pais: country, password: pin });

    if (response && response.status === 200) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fiscalizationMailMsg"), duration: 10000, severity: "success" }));
      dispatch({ type: REQUEST_LOGIN_FULFILLED, payload: response.data });
    }
    return response;
  } catch (e) {
    dispatch({ type: REQUEST_LOGIN_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getLocalizedString("fiscalizationMailMsg"), duration: 10000, severity: "success" }));
  }
};

export const getCountriesSocietiesFromServer = () => async (dispatch) => {
  dispatch({ type: GET_COUNTRIES_SOCIETIES });

  try {
    let response = await getCountrySocieties();

    if (response && response.status === 200) {
      dispatch({ 
        type: GET_COUNTRIES_SOCIETIES_FULFILLED, 
        payload: {
          societies: response.data.countrySocieties, 
          countryCode: response.data.pais, 
          countryName: response.data.nombre 
        } 
      });
    }
    return response;
  } catch (e) {
    dispatch({ type: GET_COUNTRIES_SOCIETIES_REJECTED, payload: e });
    if (e.response.status === 403) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fiscalizationInvalidToken"), duration: 10000, severity: "error" }));
      return e.response.status;
    }
  }
};

export const getSocietyDocsFromServer = (society, idFiscal) => async (dispatch) => {
  dispatch({ type: GET_DOCS });

  try {
    let query = `?society=${society}&idFiscal=${idFiscal}`;

    let response = await getSocietyDocs(query);

    if (response && response.status === 200) {
      dispatch({ type: GET_DOCS_FULFILLED, payload: response.data.societyDocs });
    }

    return response;
  } catch (e) {
    dispatch({ type: GET_DOCS_REJECTED, payload: e });
    if (e.response.status === 403) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fiscalizationInvalidToken"), duration: 10000, severity: "error" }));
      return e.response.status;
    }
  }
};

export const previewDocumentFromServer = (code, society, client) => async (dispatch) => {
  dispatch({ type: PREVIEW_DOC });

  try {

    let response = await previewDocumentAPI({ code: code, society: society, client: client });

    if (response && response.status === 200) {
      dispatch({ type: PREVIEW_DOC_FULFILLED });
    }

    return response;
  } catch (e) {
    dispatch({ type: PREVIEW_DOC_REJECTED, payload: e });
    if (e.response.status === 403) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fiscalizationInvalidToken"), duration: 10000, severity: "error" }));
      return e.response.status;
    }
  }
};

export const recoverPinAction = (mail) => async dispatch => {
  dispatch({ type: CHANGE_PIN });
  try {
    let response = await lostPassword({ mail });
    dispatch({ type: CHANGE_PIN_FULFILLED, payload: response.data });

    if (response && response.status === 200) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fiscalizationPassMail"), duration: 10000, severity: "success" }));
    }

    return response;
  } catch (e) {
    dispatch({ type: CHANGE_PIN_REJECTED, payload: e });
    dispatch(openErrorDialogAction(e));
    dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
  }
};

export const setNewPinAction = (code, password) => async dispatch => {
  dispatch({ type: CHANGE_PIN });
  try {
    let response = await changePassword({ code, password });
    dispatch({ type: CHANGE_PIN_FULFILLED, payload: response.data });

    if (response && response.status === 200) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fiscalizationPassUpdated"), duration: 10000, severity: "success" }));
    }

    return response;
  } catch (e) {
    dispatch({ type: CHANGE_PIN_REJECTED, payload: e });
    if (e.response.data.error === "INVALID_CODE") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fiscalizationRecoveryCodeInvalid"), duration: 10000, severity: "error" }));
    }
  }
};