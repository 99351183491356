import React, { useState, useEffect } from "react";
import { Typography, Button, Grid, Toolbar, TextField, MenuItem, useMediaQuery } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { loginAction, setNewPinAction, getCountriesFromServer, recoverPinAction } from "../actions";
import { getCountriesSelector } from "../selectors";
import { valueIsOfType, valueTypes } from "@icarius-utils/types";
import IcariusLogo from "@icarius-assets/images/icariusLogo";
import { generalColors } from "@icarius-utils/colors";
import Loader from "@icarius-common/loader";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const styles = () => ({
  root: {
    color: "white",
  },

  textField: {
    '& label.Mui-focused': {
      color: 'white',
    },
  },

  cssLabel: {
    color: 'white'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${generalColors.red} !important`,
    },
    color: "white",
  },

  cssFocused: {
    color: 'white',
  },

  notchedOutline: {
    borderRadius: "10px",
    borderWidth: '2px',
    borderColor: `${generalColors.red} !important`,
  },
});

const FiscalizationLogin = (props) => {

  const {
    changePinIsOpen,
    changePinCode,
    changePinCallback,
    loading,
    classes
  } = props;

  const [country, setCountry] = useState(null);
  const [pin, setPin] = useState("");
  const [recoverPinIsOpen, setRecoverPinIsOpen] = useState(false);
  const [mail, setMail] = useState("");

  const dispatch = useDispatch();
  const countries = useSelector(getCountriesSelector);
  
  useEffect(() => {
    !changePinIsOpen && dispatch(getCountriesFromServer());
  }, [dispatch, changePinIsOpen])
  
  const handleChangeMailInput = (value) => setMail(value);

  const mailIsValid = () => {
    return mail && valueIsOfType(mail, valueTypes.email) && mail.length >= 1 && mail.length <= 256;
  }

  const handleChangePinInput = (value) => setPin(value);

  const pinIsValid = () => {
    return pin && valueIsOfType(pin, valueTypes.pin) && pin.length >= 6 && pin.length <= 20;
  }

  const handleRecoverPin = () => {
    if (mailIsValid) {
      dispatch(recoverPinAction(mail))
        .then((resp) => {
          if (resp === 200) {
            setRecoverPinIsOpen(false);
          }
        })
    }
  }

  const handleSetNewPin = () => {
    if (pinIsValid()) {
      dispatch(setNewPinAction(changePinCode, pin))
        .then(() => changePinCallback())
    }
  }

  const isDataValid = () => {
    return country && pinIsValid();
  }

  const handleLogin = () => {
    if (isDataValid()) {
      dispatch(loginAction(country, pin))
        .then(() => {
          setCountry(null);
          setPin("");
        });
    }
  };

  const padding = 21;
  const shouldAdjustView = useMediaQuery(`(max-width:1000px)`);

  return (
    <section style={{ width: "100%", height: "100%" }}>
      <Loader open={loading} />
      <div style={{ position: "fixed", top: 0, marginLeft: 40, marginTop: 20 }}>
        <Toolbar>
          <Typography
            className="whiteText"
            style={{
              fontSize: "1.25rem",
              fontWeight: 400,
            }}
            variant="h6"
            id={"toolbar-title"}>
            {getLocalizedString("fiscalizationLoginTitle")}
          </Typography>
        </Toolbar>
      </div>
      <div style={{ height: "100%", display: "flex", alignItems: "center", marginTop: -50 }}>
        <Grid container direction={shouldAdjustView ? "column" : "row"} alignItems={shouldAdjustView && "center"} style={{ marginTop: recoverPinIsOpen ? "-26px" : 0 }}>

          <Grid item container xs={shouldAdjustView ? 12 : 6} justify={shouldAdjustView ? "center" : "flex-end"}>
            <IcariusLogo theme={"dark"} height={70} />
          </Grid>

          {
            !recoverPinIsOpen &&
            <Grid item container justify={shouldAdjustView ? "center" : "flex-start"} xs={shouldAdjustView ? 12 : 6}>
              <Grid item container direction="column" alignItems={shouldAdjustView && "center"}>
                {
                  !changePinIsOpen &&
                  <Grid item>
                    <div style={{ paddingTop: 10 }}>
                      <TextField
                        select
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        label={getLocalizedString("country")}
                        variant="outlined"
                        value={country}
                        onChange={e => setCountry(e.target.value)}
                        style={{ width: "400px " }}
                      >
                        {
                          countries.map((item) => (
                            <MenuItem
                              className={"whiteText"}
                              key={item.id}
                              value={item.id}>
                              {item.valor}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  </Grid>
                }

                <Grid item>
                  <div style={{ paddingTop: padding }}>
                    <TextField
                      className={classes.textField}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                        },
                        inputMode: "password"
                      }}
                      label={getLocalizedString("pin") + " (" + getLocalizedString("key") + ")"}
                      variant="outlined"
                      type="password"
                      value={pin}
                      onChange={e => handleChangePinInput(e.target.value)}
                      style={{ width: "400px " }}
                    />
                    <Typography style={{ marginLeft: 10, marginTop: 5 }} variant="subtitle2" className="whiteText">
                      {getLocalizedString("fiscalizationPinReq")}
                    </Typography>
                  </div>
                </Grid>

                <Grid item container align="center" direction="column" alignItems={shouldAdjustView && "center"}>
                  <Button
                    disabled={changePinIsOpen ? !pinIsValid() : !isDataValid()}
                    style={{ marginTop: padding, width: "400px", borderRadius: "6px", backgroundColor: generalColors.red, height: "52px" }}
                    disableRipple={true}
                    variant={"contained"}
                    type={"submit"}
                    className={"whiteText"}
                    onClick={changePinIsOpen ? handleSetNewPin : handleLogin}>
                    <Typography style={{ textTransform: "none", fontSize: "20px", fontWeight: 400 }} variant={"button"}>
                      {changePinIsOpen ? getLocalizedString("fiscalizationCambiar") : getLocalizedString("fiscalizationIngresar")}
                    </Typography>
                  </Button>

                  {
                    !changePinIsOpen &&
                    <Button
                      style={{ marginTop: padding, width: "400px", borderRadius: "6px", backgroundColor: generalColors.red, height: "52px" }}
                      disableRipple={true}
                      variant={"contained"}
                      type={"submit"}
                      className={"whiteText"}
                      onClick={() => setRecoverPinIsOpen(true)}>
                      <Typography style={{ textTransform: "none", fontSize: "20px", fontWeight: 400 }} variant={"button"}>
                        {getLocalizedString("fiscalizationRecuperarPass")}
                      </Typography>
                    </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
          }

          {
            recoverPinIsOpen &&
            <Grid item container justify={shouldAdjustView ? "center" : "flex-start"} xs={shouldAdjustView ? 12 : 6} style={{ paddingTop: 25 }}>
              <div style={{ paddingTop: padding }}>
                <TextField
                  className={classes.textField}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    inputMode: "email"
                  }}
                  style={{ width: "400px " }}
                  label={getLocalizedString("email")}
                  variant="outlined"
                  type="email"
                  value={mail}
                  onChange={e => handleChangeMailInput(e.target.value)}
                />
              </div>

              <Grid item container align="center" direction="column" alignItems={shouldAdjustView && "center"}>
                <Button
                  style={{ marginTop: padding, width: "400px", borderRadius: "6px", backgroundColor: generalColors.red, height: "52px" }}
                  disableRipple={true}
                  disabled={!mailIsValid()}
                  variant={"contained"}
                  type={"submit"}
                  className={"whiteText"}
                  onClick={handleRecoverPin}>
                  <Typography style={{ textTransform: "none", fontSize: "20px", fontWeight: 400 }} variant={"button"}>
                    {getLocalizedString("submit")}
                  </Typography>
                </Button>

                <Button
                  style={{ marginTop: padding, width: "400px", borderRadius: "6px", backgroundColor: generalColors.red, height: "52px" }}
                  disableRipple={true}
                  variant={"contained"}
                  type={"submit"}
                  className={"whiteText"}
                  onClick={() => setRecoverPinIsOpen(false)}>
                  <Typography style={{ textTransform: "none", fontSize: "20px", fontWeight: 400 }} variant={"button"}>
                    {getLocalizedString("cancel")}
                  </Typography>
                </Button>
              </Grid>


              {/*este que viene es nomas para que no se mueva todo y quede alineado cuando oculto un campo*/}
              <div style={{ paddingTop: padding, visibility: "hidden" }}>
                <TextField
                  style={{ width: "400px " }}
                  label={getLocalizedString("email")}
                  variant="outlined"
                />
              </div>
            </Grid>
          }

        </Grid>
      </div>
    </section>
  );
}

export default withStyles(styles)(FiscalizationLogin);
