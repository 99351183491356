import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
    getFestivitiesTableAPI,
    setFestivitiesTableAPI,
    deleteFestivitiesTableAPI
} from "@icarius-connection/api";

export const getFestivitiesTableFromServer = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await getFestivitiesTableAPI();
        let rows = response.data && response.data.data;
        let tableTypes = response.data && response.data.tableTypes;

        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { rows, tableTypes },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const setFestivitiesTableToServer = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_ROWS });
    try {
        let response = await setFestivitiesTableAPI({ data });
        let rows = response.data && response.data.data;

        dispatch({
            type: actionTypes.SET_ROWS_FULFILLED,
            payload: { rows },
        });
        dispatch(openSnackbarAction({ msg: 'Valores actualizados con éxito', severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.SET_ROWS_REJECTED, payload: e });
        if (e.response.data.status === 'DUPLICATED_DATA') {
            dispatch(openSnackbarAction({ msg: "No pueden existir valores con el mismo Tipo, Fecha Desde y Fecha Hasta", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const deleteFestivitiesTableInServer = (codes) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_ROWS });
    try {
        let response = await deleteFestivitiesTableAPI({ codes });
        let rows = response.data && response.data.data;

        dispatch({
            type: actionTypes.DELETE_ROWS_FULFILLED,
            payload: { rows },
        });

        return response;
    } catch (e) {
        dispatch({ type: actionTypes.DELETE_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};