import React, { useState, useMemo } from "react";
import { CloseIcon } from "@icarius-icons";
import {
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import DialogTransition from "@icarius-common/dialogTransition";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {
    Eventcalendar,
    setOptions,
    Popup,
    Datepicker,
    localeEs,
} from '@mobiscroll/react';
import { calendarTypeColors } from "@icarius-utils/colors";
import { formatDate } from "@icarius-utils/date";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { deleteFestivitiesTableInServer } from "@icarius-pages/festivitiesTable/actions";

setOptions({
    locale: localeEs,
    theme: 'material',
    themeVariant: 'light'
});

const responsivePopup = {
    medium: {
        display: 'center',
        width: 450,
        fullScreen: false,
        touchUi: false,
        showOverlay: false
    }
};

const CalendarDialog = (props) => {

    const {
        open,
        handleClose,
        year,
        type,
        getShifts,
        gridReference,
    } = props;

    const dispatch = useDispatch();

    const [showMonth, setShowMonth] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const [popUpIsOpen, setPopUpIsOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    const handleClickEvent = (e) => {
        setSelectedEvent(e);
        setPopUpIsOpen(true);
    }

    const handleClosePopUp = () => {
        setPopUpIsOpen(false);
        setSelectedEvent(null);
        setSelectedDate(null);
    }

    const handleSubmitNewDate = () => {
        const dataSource = gridReference.getDataSource();
        const originalData = gridReference.getVisibleRows().find(item => item.dataIndex === selectedEvent.event.dataIndex);

        const dateToSet = selectedDate || selectedEvent.event.start;

        if (dataSource) {
            dataSource.store().update(
                originalData,
                {
                    ...originalData,
                    data: {
                        ...originalData.data,
                        startDate: dateToSet,
                        endDate: dateToSet,
                    }
                }
            ).then(() => {
                dataSource.reload();
                handleClosePopUp();
            })
        }
    }

    const handleDelete = () => {
        const originalData = gridReference.getVisibleRows().find(item => item.dataIndex === selectedEvent.event.dataIndex);

        dispatch(deleteFestivitiesTableInServer([originalData.data]))
            .then((resp) => {
                //limpiar el seleccionado
                gridReference.deselectAll();

                if (resp?.response?.data?.status === 'IN_USE') {
                    const keysToSelect = resp.response.data.dependants;
                    const rowsToSelect = [];
                    gridReference.getVisibleRows().forEach(node => {
                        if (keysToSelect.includes(node.data.code)) {
                            rowsToSelect.push(node.key);
                        }
                    });

                    gridReference.selectRows(rowsToSelect);
                } else {
                    gridReference.cancelEditData();

                    [originalData.data].forEach(row => {
                        gridReference.cancelEditData();
                        const ds = gridReference.getDataSource();
                        const filter = gridReference.getCombinedFilter();
                        ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
                            .done((allData) => {
                                const rowData = allData;
                                const filteredRows = rowData.filter(el => JSON.stringify(el) === JSON.stringify(row));
                                filteredRows.forEach(gridRow =>
                                    gridReference.getDataSource().store().remove(gridRow).then(() => {
                                        gridReference.refresh();
                                    })
                                )
                            });
                    })

                    dispatch(openSnackbarAction({ msg: 'Valores eliminados con éxito', severity: "success" }));
                }

                setDeleteDialogIsOpen(false);
                handleClosePopUp();
                handleClose();
            });
    }

    const handleDateChange = (newDate) => {
        const dateIsTaken = getShifts().some(item => formatDate(item.data.startDate) === formatDate(newDate));

        if (dateIsTaken) {
            dispatch(openSnackbarAction({ msg: 'La fecha elegida se encuentra en uso', severity: 'warning' }));
            return;
        }

        setSelectedDate(newDate);
    }

    const getFormattedShifts = () => {
        if (!getShifts()) return [];
        return getShifts().map((item) => {
            return ({
                color: calendarTypeColors.holiday,
                end: item.data.endDate,
                start: item.data.startDate,
                title: item.data.description,
                tooltip: item.data.description,
                dataIndex: item.dataIndex,
            })
        });
    }

    const getMainPopupButtons = () => {
        return [
            {
                handler: () => {
                    handleClosePopUp();
                },
                text: 'Cancelar',
                cssClass: 'mbsc-popup-button-secondary'
            },
            {
                handler: () => {
                    setDeleteDialogIsOpen(true);
                },
                text: 'Eliminar',
                cssClass: 'mbsc-popup-button-primary'
            },
            {
                handler: () => {
                    handleSubmitNewDate();
                },
                text: 'Cambiar fecha',
                cssClass: 'mbsc-popup-button-primary'
            },
        ];
    };

    const getDeletePopUpButtons = () => {
        return [
            {
                handler: () => {
                    setDeleteDialogIsOpen(false);
                },
                text: 'Cancelar',
                cssClass: 'mbsc-popup-button-secondary'
            },
            {
                handler: () => {
                    handleDelete()
                },
                text: 'Aceptar',
                cssClass: 'mbsc-popup-button-primary'
            },
        ];
    };

    const viewSettings = useMemo(() => {
        return ({
            calendar: {
                type: showMonth ? 'month' : 'year',
            }
        })
    }, [showMonth])

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            scroll={"paper"}
            maxWidth={"xl"}
            fullWidth>
            <DialogTitle>
                {`Feriados y días festivos - Tabla ${type} - Año ${year}`}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent style={{ paddingTop: 0 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showMonth}
                                onChange={() => setShowMonth(prev => !prev)}
                                value={showMonth}
                                color="primary"
                            />
                        }
                        label={'Mostrar mes'}
                        labelPlacement="start"
                        style={{ marginLeft: 32 }}
                    />
                </div>
                <Eventcalendar
                    showControls={showMonth}
                    view={viewSettings}
                    data={getFormattedShifts()}
                    marked={showMonth ? null : getFormattedShifts()}
                    onEventClick={handleClickEvent}
                    dragToCreate={false}
                    dragToResize={false}
                    dragToMove={false}
                    clickToCreate={true}
                    cssClass="md-employee-shifts"
                    height={'calc(100vh - 185px)'}
                    dayNamesShort={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                    defaultSelectedDate={new Date(year, 0)}
                />
                {
                    popUpIsOpen && !deleteDialogIsOpen &&
                    <Popup
                        isOpen={popUpIsOpen}
                        headerText={`${selectedEvent.event.title} - ${formatDate(selectedEvent.event.start)}`}
                        onClose={handleClosePopUp}
                        buttons={getMainPopupButtons()}
                        responsive={responsivePopup}
                        fullScreen={true}
                        contentPadding={false}
                        display="bottom"
                        cssClass="employee-shifts-popup"
                    >
                        <div className="mbsc-form-group">
                            <Datepicker
                                label='Mover a nueva fecha'
                                inputTyping={false}
                                controls={['calendar']}
                                onChange={(e) => handleDateChange(e.value)}
                                value={selectedDate || selectedEvent.event.start}
                                valid={[{
                                    start: new Date(year, 0),
                                    end: new Date(year, 11, 1),
                                }]}
                            />
                        </div>
                    </Popup>
                }
                {
                    deleteDialogIsOpen &&
                    <Popup
                        isOpen={deleteDialogIsOpen}
                        headerText={'Atención'}
                        onClose={() => setDeleteDialogIsOpen(false)}
                        buttons={getDeletePopUpButtons()}
                        responsive={responsivePopup}
                        fullScreen={true}
                        contentPadding={false}
                        display="bottom"
                        cssClass="employee-shifts-popup"
                    >
                        <div className="mbsc-form-group">
                            <Typography className='mbsc-material mbsc-textfield-wrapper'>
                                {`¿Está seguro que quiere eliminar el feriado o festivo ${formatDate(selectedEvent.event.start)} - ${selectedEvent.event.title}, para la tabla ${type}?`}
                            </Typography>
                        </div>
                    </Popup>
                }
            </DialogContent>
        </Dialog>
    );
}

export default CalendarDialog;
