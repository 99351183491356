import NAME from "./constants";

export const REQUEST_LOGIN = NAME + "/REQUEST_LOGIN";
export const REQUEST_LOGIN_FULFILLED = NAME + "/REQUEST_LOGIN_FULFILLED";
export const REQUEST_LOGIN_REJECTED = NAME + "/REQUEST_LOGIN_REJECTED";

export const CHANGE_PIN = NAME + "/CHANGE_PIN";
export const CHANGE_PIN_FULFILLED = NAME + "/CHANGE_PIN_FULFILLED";
export const CHANGE_PIN_REJECTED = NAME + "/CHANGE_PIN_REJECTED";

export const GET_COUNTRIES = NAME + "/GET_COUNTRIES";
export const GET_COUNTRIES_FULFILLED = NAME + "/GET_COUNTRIES_FULFILLED";
export const GET_COUNTRIES_REJECTED = NAME + "/GET_COUNTRIES_REJECTED";

export const GET_COUNTRIES_SOCIETIES = NAME + "/GET_COUNTRIES_SOCIETIES";
export const GET_COUNTRIES_SOCIETIES_FULFILLED = NAME + "/GET_COUNTRIES_SOCIETIES_FULFILLED";
export const GET_COUNTRIES_SOCIETIES_REJECTED = NAME + "/GET_COUNTRIES_SOCIETIES_REJECTED";

export const GET_DOCS = NAME + "/GET_DOCS";
export const GET_DOCS_FULFILLED = NAME + "/GET_DOCS_FULFILLED";
export const GET_DOCS_REJECTED = NAME + "/GET_DOCS_REJECTED";

export const PREVIEW_DOC = NAME + "/PREVIEW_DOC";
export const PREVIEW_DOC_FULFILLED = NAME + "/PREVIEW_DOC_FULFILLED";
export const PREVIEW_DOC_REJECTED = NAME + "/PREVIEW_DOC_REJECTED";

export const RESET = NAME + "/RESET";