import {
  REQUEST_LOGIN,
  REQUEST_LOGIN_FULFILLED,
  REQUEST_LOGIN_REJECTED,
  CHANGE_PIN,
  CHANGE_PIN_FULFILLED,
  CHANGE_PIN_REJECTED,
  GET_COUNTRIES,
  GET_COUNTRIES_FULFILLED,
  GET_COUNTRIES_REJECTED,
  GET_COUNTRIES_SOCIETIES,
  GET_COUNTRIES_SOCIETIES_FULFILLED,
  GET_COUNTRIES_SOCIETIES_REJECTED,
  GET_DOCS,
  GET_DOCS_FULFILLED,
  GET_DOCS_REJECTED,
  RESET,
} from "./actionTypes";

const initialState = {
  requestingLogin: false,
  countries: [],
  gettingCountries: false,
  societies: [],
  selectedCountry: null,
  gettingSocieties: false,
  changingPin: false,
  gettingDocs: false,
  docs: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET:
      return { ...initialState };
    case GET_COUNTRIES:
      return { ...state, gettingCountries: true };
    case GET_COUNTRIES_FULFILLED:
      return { ...state, gettingCountries: false, countries: action.payload };
    case GET_COUNTRIES_REJECTED:
      return { ...state, gettingCountries: false };

    case GET_DOCS:
      return { ...state, gettingDocs: true };
    case GET_DOCS_FULFILLED:
      return { ...state, gettingDocs: false, docs: action.payload };
    case GET_DOCS_REJECTED:
      return { ...state, gettingDocs: false };

    case GET_COUNTRIES_SOCIETIES:
      return { ...state, gettingSocieties: true };
    case GET_COUNTRIES_SOCIETIES_FULFILLED:
      return { 
        ...state, 
        gettingSocieties: false, 
        societies: action.payload.societies,
        selectedCountry: {
          code: action.payload.countryCode, 
          name: action.payload.countryName, 
        }        
      };
    case GET_COUNTRIES_SOCIETIES_REJECTED:
      return { ...state, gettingSocieties: false };

    case REQUEST_LOGIN:
      return { ...state, requestingLogin: true };
    case REQUEST_LOGIN_FULFILLED:
      return {
        ...state,
        requestingLogin: false,
        loginResponse: action.payload,
      };
    case REQUEST_LOGIN_REJECTED:
      return { ...state, requestingLogin: false };

    case CHANGE_PIN:
      return { ...state, changingPin: true };
    case CHANGE_PIN_FULFILLED:
      return { ...state, changingPin: false };
    case CHANGE_PIN_REJECTED:
      return { ...state, changingPin: false };
    default:
      return state;
  }
}
