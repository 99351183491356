import { useState, useMemo } from "react";

const useCalendar = (tableTypes, gridData) => {

  const [type, setType] = useState(null);
  const [year, setYear] = useState(null);
  const [typeYearDialogIsOpen, setTypeYearDialogIsOpen] = useState(false);
  const [calendarDialogIsOpen, setCalendarDialogIsOpen] = useState(false);
  const [years, setYears] = useState({});

  const types = useMemo(() => {
    let typesToUse = [...tableTypes];
    const uniqueInUse = [...new Set(gridData?.map(item => item.data.type))];
    typesToUse = typesToUse.filter(el => uniqueInUse.includes(el.key));

    uniqueInUse.forEach((type) => {
      const index = typesToUse.findIndex(item => item.key === type);
      if (index > -1) {
        const yearsInUse = [...new Set(gridData?.filter(item => item.data.type === type).map(item => item.data.year))];
        years[type] = yearsInUse;
        setYears(years);
      }
    })

    return typesToUse;
  }, [tableTypes, gridData, years])

  const getShifts = () => {
    if (!(type && year && gridData?.length)) return [];
    return gridData.filter(item => item.data.type === type && item.data.year === year)
  }

  const handleOpenTypeYearDialog = () => {
    setTypeYearDialogIsOpen(true);
  }

  const handleCloseTypeYearDialog = () => {
    setTypeYearDialogIsOpen(false);
  }

  const handleOpenCalendarDialog = () => {
    setCalendarDialogIsOpen(true);
  }

  const handleCloseCalendarDialog = () => {
    setCalendarDialogIsOpen(false);
    setType(null);
    setYear(null);
  }

  const handleSubmitTypeAndYear = (newType, newYear) => {
    setType(newType);
    setYear(newYear);
    handleOpenCalendarDialog();
  }

  const calendarFunctions = {
    handleSubmitTypeAndYear,
    handleOpenTypeYearDialog,
    handleCloseTypeYearDialog,
    handleOpenCalendarDialog,
    handleCloseCalendarDialog,
    getShifts,
  };

  const calendarData = {
    types,
    years,
    year,
    type,
    typeYearDialogIsOpen,
    calendarDialogIsOpen,
  };

  return {
    calendarData,
    calendarFunctions,
  }
}

export default useCalendar;
