import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import {
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    MenuItem,
    FormControl,
    InputLabel,
    Select
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const DuplicateDialog = (props) => {

    const { open, handleConfirm, handleClose, tableTypes, usedData } = props;
    const [selected, setSelected] = useState('');
    const dispatch = useDispatch();

    const typesToUse = useMemo(() => {
        const typesToUse = [...tableTypes];
        const uniqueInUse = [...new Set(usedData?.map(item => item.data.type))];

        uniqueInUse.forEach((itemToDelete) => {
            const index = typesToUse.findIndex(item => item.key === itemToDelete);
            if (index > -1) {
                typesToUse.splice(index, 1);
            }
        })
        return typesToUse;
    }, [tableTypes, usedData])

    const handleSubmit = () => {
        if(!selected){
            dispatch(openSnackbarAction({ msg: 'Debe seleccionar tipo de tabla', severity: "warning" }));
            return;
        }

        handleConfirm(selected);
        handleClose();
    }

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            scroll={"paper"}
            maxWidth={"sm"}
            fullWidth>
            <DialogTitle>
                {'Duplicar feriados y días festivos'}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent style={{ paddingTop: 0 }}>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel required id={`label`}>{'Tipo de tabla'}</InputLabel>
                    <Select
                        value={selected}
                        labelId={`label`}
                        id={`select`}
                        onChange={(e) => setSelected(e.target.value)}
                        margin={"none"}
                    >
                        {
                            typesToUse?.map((item) => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.key}
                                    value={item.key}
                                >
                                    {item.value}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleClose} text={'Cancelar'} />
                <ButtonDialogAction onClick={handleSubmit} isAccept text={'Duplicar'} />
            </DialogActions>
        </Dialog>
    );
}

export default DuplicateDialog;
