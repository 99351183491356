import React, { useRef, useEffect, useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import EditableGridv2 from "@icarius-common/editableGrid/editableGridv2";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import AddRowStatusBar from '@icarius-common/editableGrid/Components/addRow/addRowButton';
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { getFestivitiesTableFromServer, setFestivitiesTableToServer, deleteFestivitiesTableInServer } from "../actions";
import { getRows, getLoading, getTableTypes } from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter"
import { createDateFromDDMMYYYY, createDateFromYYYYMMDDHyphen } from "@icarius-utils/date";
import moment from "moment";
import { TodayIcon } from "@icarius-icons/index";
import ConfirmationDialog from "./dialogs/confirmationDialog";
import DuplicateDialog from "./dialogs/duplicateDialog";
import DuplicateBulkDialog from "./dialogs/duplicateBulkDialog";
import TypeYearDialog from "./dialogs/typeYearDialog";
import useCalendar from "./useCalendar";
import CalendarDialog from "./dialogs/calendarDialog";
import { openDialogAction } from "@icarius-common/dialog/actions";

const FestivitiesTable = ({ history }) => {

  const [rowToDuplicate, setRowToDuplicate] = useState();
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [duplicateBulkDialogIsOpen, setDuplicateBulkDialogIsOpen] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [shouldReloadGrid, setShouldReloadGrid] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [gridReference, setGridReference] = useState(null);
  
  const dispatch = useDispatch();
  const rowData = useSelector(getRows);
  const tableTypes = useSelector(getTableTypes);
  const isLoading = useSelector(getLoading);
  const gridRef = useRef();

  useEffect(() => {
    dispatch(getFestivitiesTableFromServer());
    window.scrollTo(0, 0);
  }, [dispatch])

  useEffect(() => {
    if (gridRef) {
      setGridReference(gridRef.current.refs.dataGrid._instance);
    }
  }, [gridRef])

  const {
    calendarData,
    calendarFunctions,
  } = useCalendar(tableTypes, gridReference?.getVisibleRows());

  const formatDate = date => {
    return Boolean(date) ? (
      date instanceof Date ?
        moment(date, "DD/MM/YYYY") : (
          typeof date === 'number' ?
            moment(date) : moment(createDateFromDDMMYYYY(date), "DD/MM/YYYY")
        )
    ) : ''
  }
  const handleSave = () => {
    const rowData = gridReference.getVisibleRows().map(item => item.data);
    const formattedData = rowData.map(row => {
      return {
        type: row.type,
        description: row.description,
        year: parseInt(row.year, 10),
        startDate: formatDate(row.startDate),
        endDate: formatDate(row.endDate),
      }
    })
    dispatch(setFestivitiesTableToServer(formattedData))
      .then((resp) => {
        //limpiar el seleccionado
        gridReference.deselectAll()

        if (resp?.response?.data?.status === 'DUPLICATED_DATA') {
          const rowsToSelect = [];
          resp.response.data.codes.forEach(element => {
            gridReference.getVisibleRows().forEach(node => {
              if (Number(node.data.type) === Number(element.type) &&
                node.data.startDate === element.startDate &&
                node.data.endDate === element.endDate &&
                Number(node.data.year) === Number(element.year) &&
                node.data.description === element.description) {
                rowsToSelect.push(node.key);
              }
            });

            gridReference.selectRows(rowsToSelect);
          })
        } else {
          setShouldReloadGrid(true);
        }

        if (resp?.status === 200) {
          setDataChanged(false);
        }
      })
  };

  const handleDuplicate = (newType) => {
    if (rowToDuplicate) {
      const itemsToDuplicate = gridReference.getVisibleRows().filter(item => item.data.type === rowToDuplicate.type &&
        item.data.year === rowToDuplicate.year &&
        item.data.startDate === rowToDuplicate.startDate &&
        item.data.endDate === rowToDuplicate.endDate &&
        item.data.description === rowToDuplicate.description).map(item => item.data);

      itemsToDuplicate.forEach((row) => {
        const dataSource = gridReference.getDataSource();
        let data = { ...row, type: newType };

        dataSource.store().insert(data).then(function () {
          dataSource.reload();
        })
      });

      setRowToDuplicate(null);
      dispatch(openSnackbarAction({ msg: "La tabla de feriados ha sido duplicada con éxito", severity: "success" }));
    }
  }

  const handleBulkDuplicate = ({ oldType, oldYear, type, year }) => {
    const itemsToDuplicate = gridReference.getVisibleRows().filter(item => item.data.type === oldType && item.data.year === oldYear).map(item => item.data);

    itemsToDuplicate.forEach((row) => {
      const dataSource = gridReference.getDataSource();
      let data = JSON.parse(JSON.stringify({ ...row, type, year }));
      const startDate = createDateFromYYYYMMDDHyphen(data.startDate);
      const endDate = createDateFromYYYYMMDDHyphen(data.endDate)
      data.startDate = new Date(startDate);
      data.startDate.setFullYear(year);


      data.endDate = new Date(endDate);
      data.endDate.setFullYear(year);
      // Me fijo que no haya problema con los años bisiestos
      if (moment(startDate).date() === moment(data.startDate).date() && moment(startDate).month() === moment(data.startDate).month()) {
        dataSource.store().insert(data).then(function () {
          dataSource.reload();
        })
      }
    });

    dispatch(openSnackbarAction({ msg: "La tabla de feriados ha sido duplicada con éxito", severity: "success" }));
  }

  const handleOpenDuplicateDialog = () => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridReference.getSelectedRowsData();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
      return;
    }

    setRowToDuplicate(selectedRows[0]);
    setDuplicateDialogIsOpen(true);
  }

  const handleOpenDuplicateBulkDialog = () => {
    setDuplicateBulkDialogIsOpen(true);
  }

  const handleOpenDeleteDialog = () => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridReference.getSelectedRowsData();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Desea eliminar las filas seleccionadas?',
      onConfirm: () => deleteRowsFromServer(selectedRows),
    }));
  }

  const deleteRowsLocally = (rowsToDelete) => {
    gridReference.cancelEditData();
    if (Array.isArray(rowsToDelete)) {
      const ds = gridReference.getDataSource();
      const filter = gridReference.getCombinedFilter();
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          const rowData = allData;
          rowsToDelete.forEach(row => {
            const filteredRows = rowData.filter(el => JSON.stringify(el) === JSON.stringify(row));
            filteredRows.forEach(gridRow =>
              gridReference.getDataSource().store().remove(gridRow).then(() => {
                gridReference.refresh();
              })
            )
          });
        });
    }
    dispatch(openSnackbarAction({ msg: 'Valores eliminados con éxito', severity: "success" }));
  }

  const deleteRowsFromServer = (rowsToDelete) => {
    const codesToSend = rowsToDelete.filter(row => !row.hasOwnProperty("__KEY__"));

    if (codesToSend.length > 0) {
      dispatch(deleteFestivitiesTableInServer(codesToSend))
        .then((resp) => {
          //limpiar el seleccionado
          gridReference.deselectAll();

          if (resp?.response?.data?.status === 'IN_USE') {
            const keysToSelect = resp.response.data.dependants;
            const rowsToSelect = [];
            gridReference.getVisibleRows().forEach(node => {
              if (keysToSelect.includes(node.data.code)) {
                rowsToSelect.push(node.key);
              }
            });

            gridReference.selectRows(rowsToSelect);
          } else {
            deleteRowsLocally(rowsToDelete);
          }
        })
    } else if (rowsToDelete.length > codesToSend.length) {
      deleteRowsLocally(rowsToDelete);
    }
  }

  const handleGoBack = () => {
    history.push(paths.organizationalDefinitions);
  }

  const handleCheckGoBack = () => {
    if (dataChanged) {
      setConfirmationDialogIsOpen(true);
    } else {
      handleGoBack();
    }
  }

  const handleExportGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Feriados y días festivos');

    exportDataGrid({
      component: gridReference,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.value) {
          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'num' && gridCell.value !== "") {
              excelCell.value = parseFloat(gridCell.value);
              excelCell.numFmt = '_(#,##0.000_);_((#,##0.000)';
            }
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Feriados y días festivos.xlsx');
      });
    });
  }

  const saveItem = () => (
    <CustomIconButton
      title={"Guardar"}
      onClick={handleSave}
      type={"save"}
    />
  )

  const deleteItem = () => (
    <CustomIconButton
      title={"Eliminar"}
      onClick={handleOpenDeleteDialog}
      type={"delete"}
    />
  )

  const duplicateItem = () => (
    <CustomIconButton
      title={"Duplicar"}
      onClick={handleOpenDuplicateDialog}
      type={"duplicate"}
    />
  )

  const duplicateBulkItem = () => (
    <CustomIconButton
      title={"Duplicar de forma masiva"}
      onClick={handleOpenDuplicateBulkDialog}
      type={"bulkDuplicate"}
    />
  )

  const addRowButton = () => (
    <AddRowStatusBar {...gridRef.current} />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleCheckGoBack}
      type={"goBack"}
    />
  )

  const calendarButton = () => (
    <CustomIconButton
      title={'Ver en calendario'}
      onClick={calendarFunctions.handleOpenTypeYearDialog}
    >
      <TodayIcon />
    </CustomIconButton>
  )

  const exportGrid = () => (
    <CustomIconButton
      title={"Exportar"}
      onClick={handleExportGrid}
      type={"excel"}
    />
  )

  const selectDataSources = name => {
    if (name === "type") {
      return tableTypes;
    } else {
      return [];
    }
  }

  const formattedData = rowData.map(row => {
    return {
      ...row,
      type: Number(row.type),
      year: Number(row.year),
      startDate: Boolean(row.startDate) ? createDateFromDDMMYYYY(row.startDate) : null,
      endDate: Boolean(row.endDate) ? createDateFromDDMMYYYY(row.endDate) : null
    }
  })

  useEffect(() => {
    rowData && setShouldReloadGrid(true);
  }, [rowData])

  return (
    <CommonPage
      title={'Feriados y días festivos'}
      menuItems={[goBackButton, addRowButton, saveItem, duplicateItem, duplicateBulkItem, exportGrid, calendarButton, deleteItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <EditableGridv2
        selectDataSources={selectDataSources}
        setShouldReloadGrid={setShouldReloadGrid}
        shouldReloadGrid={shouldReloadGrid}
        setDataChanged={setDataChanged}
        ref={gridRef}
        gridName="festivitiesTable"
        rowData={formattedData}
        columns={getColumnDefByPage(paths.festivitiesTable)}
      />
      {
        confirmationDialogIsOpen &&
        <ConfirmationDialog
          open={confirmationDialogIsOpen}
          handleConfirm={handleGoBack}
          handleClose={() => setConfirmationDialogIsOpen(false)}
        />
      }
      {
        duplicateDialogIsOpen &&
        <DuplicateDialog
          open={duplicateDialogIsOpen}
          tableTypes={tableTypes}
          usedData={gridReference.getVisibleRows()}
          handleConfirm={handleDuplicate}
          handleClose={() => setDuplicateDialogIsOpen(false)}
        />
      }
      {
        duplicateBulkDialogIsOpen &&
        <DuplicateBulkDialog
          open={duplicateBulkDialogIsOpen}
          tableTypes={tableTypes}
          usedData={gridReference.getVisibleRows()}
          handleConfirm={handleBulkDuplicate}
          handleClose={() => setDuplicateBulkDialogIsOpen(false)}
        />
      }
      {
        calendarData.typeYearDialogIsOpen &&
        <TypeYearDialog
          open={calendarData.typeYearDialogIsOpen}
          types={calendarData.types}
          years={calendarData.years}
          handleConfirm={calendarFunctions.handleSubmitTypeAndYear}
          handleClose={calendarFunctions.handleCloseTypeYearDialog}
        />
      }
      {
        calendarData.calendarDialogIsOpen &&
        <CalendarDialog
          open={calendarData.calendarDialogIsOpen && !isLoading}
          year={calendarData.year}
          type={calendarData.type}
          getShifts={calendarFunctions.getShifts}
          gridReference={gridReference}
          handleClose={calendarFunctions.handleCloseCalendarDialog}
        />
      }
    </CommonPage>
  );
}

export default FestivitiesTable;
