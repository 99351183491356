import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import {
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Grid,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const gridStyle = { height: 75, padding: "10px" };

const TypeYearDialog = (props) => {

    const dispatch = useDispatch();
    const { open, handleConfirm, handleClose, types, years } = props;
    const [type, setType] = useState('');
    const [year, setYear] = useState('');

    const handleSubmit = () => {
        if (!type || !year) {
            dispatch(openSnackbarAction({ msg: 'Debe completar todos los valores', severity: "warning" }));
            return;
        }

        if (year && !/(^[1-2]\d{3}$)/.test(year)) {
            dispatch(openSnackbarAction({ msg: 'El año no es válido', severity: "warning" }));
            return;
        }

        handleConfirm(type, year);
        handleClose();
    }

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            scroll={"paper"}
            maxWidth={"sm"}
            fullWidth>
            <DialogTitle>
                {'Seleccionar tipo de tabla y año'}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent style={{ paddingTop: 0 }}>
                <Grid container item xs={12}>
                    <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel required id={`label`}>{'Tipo de tabla'}</InputLabel>
                            <Select
                                value={type}
                                labelId={`label`}
                                id={`select`}
                                onChange={(e) => setType(e.target.value)}
                                margin={"none"}
                            >
                                {
                                    types?.map((item) => (
                                        <MenuItem
                                            className={"whiteText"}
                                            key={item.key}
                                            value={item.key}
                                        >
                                            {item.value}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel required id={`label`}>{'Año'}</InputLabel>
                            <Select
                                value={year}
                                labelId={`label`}
                                id={`select`}
                                onChange={(e) => setYear(e.target.value)}
                                margin={"none"}
                            >
                                {
                                    years[type]?.map((item) => (
                                        <MenuItem
                                            className={"whiteText"}
                                            key={item}
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleClose} text={'Cancelar'} />
                <ButtonDialogAction onClick={handleSubmit} isAccept text={'Ver'} />
            </DialogActions>
        </Dialog>
    );
}

export default TypeYearDialog;
