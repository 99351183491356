import NAME from "./constants";


export const loading = store => 
    store[NAME].requestingLogin || 
    store[NAME].gettingCountries || 
    store[NAME].gettingSocieties || 
    store[NAME].gettingDocs || 
    store[NAME].changingPin;
    
export const getCountriesSelector = store => store[NAME].countries;
export const getSocietiesSelector = store => store[NAME].societies;
export const getSelectedCountryDataSelector = store => store[NAME].selectedCountry;
export const getDocsSelector = store => store[NAME].docs;

