import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";

export default [
    {
        pages: [paths.fiscalization],
        config: {
            headerName: getLocalizedString("idFiscal"),
            field: "idFiscal",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.fiscalization],
        config: {
            headerName: getLocalizedString("companyName"),
            field: "nombre",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.fiscalization],
        config: {
            headerName: getLocalizedString("quantityDocsSigned"),
            field: "cantidad",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.showDocs],
        config: {
            headerName: " ",
            field: "download",
            cellRenderer: "DownloadIconRenderer",
            width: 40,
            filter: "",
        }
    },
    {
        pages: [paths.showDocs],
        config: {
            headerName: getLocalizedString("state"),
            field: "status",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.showDocs],
        config: {
            headerName: getLocalizedString("receiver"),
            field: "receiver",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.showDocs],
        config: {
            headerName: getLocalizedString("idFiscalReceptor"),
            field: "idReceiver",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.showDocs],
        config: {
            headerName: getLocalizedString("dateTimeSign"),
            field: "dateTime",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.showDocs],
        config: {
            headerName: getLocalizedString("signDocName"),
            field: "document",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.showDocs],
        config: {
            headerName: getLocalizedString("signDocCod"),
            field: "code",
            filter: "agTextColumnFilter",
        }
    },
]